@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.color-nav {
    background-color: #e3e3e3;
}

body {
    font-family:  "Times New Roman", Times, serif !important;

    /* font-family: Courier,serif !important; */
}

.color-card {
    background-color: #e3e3e3;
}

.footer-font {
    font-size: 14px;
}